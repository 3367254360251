
import Vue from "vue";
import store from "@/store";
import API from "@/api/API";
import ClientData from "@/modules/orders/components/ClientData.vue";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import CommentsPreview from "@/modules/orders/components/CommentsPreview.vue";
import Payments from "@/modules/orders/components/Payments.vue";
import Documents from "@/modules/orders/components/Documents.vue";
import OrderServices from "@/modules/orders/components/OrderServices.vue";
import OrderActions from "@/modules/orders/components/OrderActions.vue";
import orderActionsMixin from "@/modules/orders/mixins/orderActionsMixin";
import common_actions from "@/modules/orders/mixins/common_actions";

export default Vue.extend({
  name: "AtaCreateOrder",

  components: {
    OrderActions,
    OrderServices,
    Payments,
    CommentsPreview,
    ClientData,
    Documents
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  directives: { mask },

  mixins: [common_actions, orderActionsMixin],

  data: () => ({
    rules,
    paymentsTotalPrice: 0 as number,
    selectedField: "" as string,
    powerOfAttorney: null as any,
    loading: false as boolean,
    loadingPayments: false as boolean,
    loadingCarnetIssused: false as boolean,
    selectedClient: null as null | SelectComponentInterface,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      order: {
        subdivision_id: 1,
        branch_id: 1,
        source: "client",
        client_delegate: "aaij",
        document_type: "new",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        power_of_attorney: "beneficiary" as string
      } as any,
      details: {
        services: [] as Array<any>
      } as any
    } as any,
    errorMessages: {} as any,
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      client_types: [] as Array<SelectComponentInterface>,
      release_types: [] as Array<SelectComponentInterface>,
      areas: ["agro", "other"] as Array<string>,
      reasons: [] as Array<SelectComponentInterface>,
      specialists: [] as Array<SelectComponentInterface>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 2,
    totalSum: 0 as number,
    isShowDocuments: true as boolean,
    isShowDocumentsNumber: false as boolean,
    isLoading: false,
    comments: {} as any,
    oldModel: null as any,
    forceKey: 0 as number,
    advice: null as any
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
        this.oldModel = JSON.parse(JSON.stringify(this.model));

        if (this.model.hasOwnProperty("additions")) {
          if (this.model.additions.hasOwnProperty("notes")) {
            this.selectedTab = 1;
            this.comments = this.parseComments(this.model.additions.notes);
          }
        }
        if (
          this.model.documents.some((item: any) => item.file_type === "advice")
        ) {
          this.advice = this.model.documents.find(
            (item: any) => item.file_type === "advice"
          ).file;
        }
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    }
  },

  computed: {
    canEditOrder(): boolean {
      return this.model.order.status?.alias === "processing";
    },
    needCertificationConfirmation() {
      return this.model.order.status.alias === "check_beneficiary";
    },
    canChangedReleaseMode() {
      return this.oldModel?.order.release_mode === "urgent";
    },
    isViewCarnetIssuance() {
      return this.model.order?.status?.alias === "paid";
    },
    isDataChanged() {
      if (this.model && this.oldModel) {
        return JSON.stringify(this.model) !== JSON.stringify(this.oldModel);
      }
      return false;
    },
    isOriginalDocument() {
      return this.model.order.document_type === "new";
    },
    formattedDate() {
      return this.$moment(this.model.order.document_valid_from, "YYYY-MM-DD")
        .add(1, "years")
        .subtract(1, "days")
        .format("DD.MM.YYYY");
    },
    areas() {
      return this.selects.areas.map((item: any) => ({
        value: item,
        text: this.$t(`orders.areas.${item}`, this.model.order.language)
      }));
    },
    isBeneficiaryAndProcessing() {
      return (
        this.model.order.status.id === 1 && this.model.order.source === "client"
      );
    },
    orderFulfilled() {
      return this.model.order.status.id === 4;
    },
    canSaveOrder() {
      return this.model.order.status !== "canceled";
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    checkPowertOfAttorney() {
      if (this.model.order.power_of_attorney === "beneficiary") {
        if (
          this.model.documents.some(
            (item: any) => item.file_type === "confirm_represent_power"
          )
        ) {
          const index = this.model.documents.findIndex(
            (item: any) => item.file_type === "confirm_represent_power"
          );

          if (index !== -1) {
            this.model.documents.splice(index, 1);
          }
        }
        delete this.model.order.mpower_idnp;
        return;
      }

      if (this.model.order.power_of_attorney === "classic") {
        delete this.model.order.mpower_idnp;
        return;
      }

      if (this.model.order.power_of_attorney === "mpower") {
        if (
          this.model.documents.some(
            (item: any) => item.file_type === "confirm_represent_power"
          )
        ) {
          const index = this.model.documents.findIndex(
            (item: any) => item.file_type === "confirm_represent_power"
          );

          if (index !== -1) {
            this.model.documents.splice(index, 1);
          }
        }
      }
    },
    changeKey() {
      this.forceKey = this.forceKey++;
    },
    async getData() {
      this.$emit("update");
    },
    onTabChange(tabIndex: number): void {
      this.loadingPayments = tabIndex === 2;
      this.loadingCarnetIssused = tabIndex === 3;
    },
    parseComments(array: any) {
      const object = {} as any;

      for (const key of array) {
        object[key.key] = key;
      }
      return object;
    },
    isSelectBeneficiary(): boolean {
      return !this.model.order.client_id;
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        const response = await Promise.all([
          API.purposeOfUse().getList(this.model.order.service_type_id, headers),
          API.orders().getOrderReleaseModes(),
          API.clients().getClientTypes(),
          API.orders().getCardTypes(this.model.order.service_type_id),
          API.orders().getLanguages()
        ]);

        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    updateVat(index: number): void {
      this.model.details.services[index].sum_no_vat = Number(
        this.model.details.services[index].sum_no_vat
      );
      this.model.details.services[index].sum_with_vat = this.addVat(
        Number(this.model.details.services[index].sum_no_vat)
      );
      this.model.details.services[index].remained_sum =
        Number(this.model.details.services[index].sum_with_vat) -
        Number(this.model.details.services[index].paid_sum);
      this.getTotalSum();
    },
    addVat(sum: number): number {
      return sum + (sum * 20) / 100;
    },
    removeService(service: any) {
      if (Array.isArray(service)) {
        for (const item of service) {
          const serviceIndex = this.model.details.services.findIndex(
            (el: any) => el.service_id === item
          );
          if (serviceIndex !== -1) {
            this.model.details.services.splice(serviceIndex, 1);
          }
        }
      } else {
        const serviceIndex = this.model.details.services.findIndex(
          (item: any) => item.service_id === service
        );
        if (serviceIndex !== -1) {
          this.model.details.services.splice(serviceIndex, 1);
        }
      }
      this.getTotalSum();
    },
    setService(service: any): void {
      if (
        !this.model.details?.services?.some(
          (item: any) => item.service_id === service.service_id
        )
      ) {
        this.model.details.services.push(service);
      } else {
        const serviceIndex = this.model.details.services.findIndex(
          (item: any) => item.service_id === service.service_id
        );
        this.model.details.services[serviceIndex] = {
          ...this.model.details.services[serviceIndex],
          ...service
        };
      }
      this.getTotalSum();
    },
    getTotalSum() {
      this.totalSum = this.model.details.services.reduce(
        (total: number, { sum_with_vat }: { sum_with_vat: number }) => {
          if (sum_with_vat) {
            return total + Number(sum_with_vat);
          }
          return total;
        },
        0
      );
    },
    async submit(): Promise<void> {
      this.isLoading = true;
      const form = this.$refs.form as Vue;

      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney.file) {
            this.model.documents.push(this.powerOfAttorney);
          }
          const model = { ...this.model };

          model.order.client_id = model.order.client.id;
          model.order.document_valid_until = this.$moment(
            this.model.order.document_valid_from,
            "YYYY-MM-DD"
          )
            .add(1, "years")
            .subtract(1, "days")
            .format("YYYY-MM-DD");

          this.checkPowertOfAttorney();

          this.model = await this.$API
            .orders()
            .edit(Number(this.$route.params.id), model);
          this.oldModel = JSON.parse(JSON.stringify(this.model));
          form.updateGuard();
          this.$emit("update");

          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        } else {
          this.selectedTab = 1;
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          if (e.errors.length === 1) {
            if (
              e.errors.hasOwnProperty("order") &&
              e.errors.order.hasOwnProperty("required_guaranty_sum")
            ) {
              this.selectedTab = 1;
            }
          } else if (e.errors.length) {
            this.selectedTab = 0;
          }

          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);

          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        purpose,
        releaseModes,
        clientTypes,
        cardTypes,
        languages
      ] = response;

      this.selects.languages = languages.items;
      this.selects.purpose = purpose;
      this.selects.release_types = releaseModes.items;
      this.selects.client_types = clientTypes;
      this.selects.cardTypes = cardTypes.items;
      this.model.order.document_type = cardTypes.default;

      this.setDefaultTab();
      this.$nextTick(() => {
        this.oldModel = JSON.parse(JSON.stringify(this.model));
        this.$forceUpdate();
      });
    },
    async search(value: string, key: string): Promise<any> {
      try {
        return await (this.$API as any)[key]().liveSearch(value);
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    async searchClient(value: string, key: string): Promise<any> {
      try {
        return {
          items: await (this.$API as any)[key]().liveSearchClient(value)
        };
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    setClient(client: any): void {
      this.model.order.client_id = client.id;
      this.model.order.client = client;
    },
    clearClient(): void {
      delete this.model.order.client_id;
      delete this.model.order.client;
    },
    changeData(items: Array<any>) {
      this.model.details.countries = items;
    },
    setParentCard(card: any) {
      if (card?.value) {
        this.model.order.parent_document_id = card.value;
      }
    },
    setPurposeDescription() {
      const index = this.selects.purpose.findIndex(
        (item: any) => item.value === this.model.details.purpose_id
      );

      if (index !== -1 && !this.model.details.purpose_description) {
        this.model.details.purpose_description = this.selects.purpose[
          index
        ].description;
      }
    },
    setBeneficiary(event: any) {
      this.selectedClient = { value: event.id, text: event.name };
      this.setClient(event);
    },
    loadDocuments(val: any) {
      this.model.documents = val;
    },
    setDefaultTab() {
      if (
        this.model.order.status &&
        ["processing", "issued", "canceled"].includes(
          this.model.order.status.alias
        )
      ) {
        this.selectedTab = 0;
        return;
      } else {
        this.selectedTab = 2;
      }
    },
    setPowerOfAttorney(event: any) {
      this.powerOfAttorney = { ...event };
    },
    async close(): Promise<void> {
      if (Object.keys(this.$route.query).length) {
        await this.$router.push(this.$route.path);
      }
      await this.$router.push("/orders");
    },
    downloadAdvice() {
      if (!this.advice || !this.advice.url) {
        return;
      }
      const element = document.createElement("a");
      element.setAttribute("href", this.advice.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    reload(): void {
      this.$emit("update");
    },
    setPaymentsPrice(price: number) {
      this.paymentsTotalPrice = price;
    },
    async downloadDocument(): Promise<void> {
      // window.open(
      //   `${process.env.VUE_APP_API_SERVER_URL}/orders/${this.$route.params.id}/pdf/last_certificate_templates`
      // );
      try {
        const file = await this.$API
          .orders()
          .printAaijOrder(Number(this.$route.params.id));
        this.downloadFile(file);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async confirmCertificationData(): Promise<void> {
      try {
        await this.$API
          .orders()
          .changeOrderStatus(this.model.order.id, "checked_beneficiary");
        await this.$emit("update");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
